import { CommonModule, NgOptimizedImage } from '@angular/common'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
// angular
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { DateAdapter, MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltip } from '@angular/material/tooltip'
import { TranslateModule } from '@ngx-translate/core'
import { AutosizeModule } from 'ngx-autosize'
import { QuantitySelectComponent } from '../proficloud/components/quantity-select/quantity-select.component'
import { DeviceItemComponent } from '../proficloud/modules/device-management/components/device-item/device-item.component'
// app
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatButton, MatIconButton } from '@angular/material/button'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { PcInputSelectComponent } from 'src/app/modules/shared/components/pc-input-select/pc-input-select.component'
import { DeviceOnlineIconComponent } from '../proficloud/modules/device-management/components/device-online-icon/device-online-icon.component'
import {
  DeviceCssPrefixPipe,
  lastOnlineTextPipe,
  mapIconColorPipe,
  stateIconColorPipe,
  stateIconNamePipe,
} from '../proficloud/modules/device-management/pipes/device-pipes.pipe'
import { AuthCallbackComponent, RegistrationCallbackComponent } from './components/auth-callback/auth-callback.component'
import { PcButtonDropdownComponent } from './components/buttons/pc-button-dropdown/pc-button-dropdown.component'
import { PCButtonComponent } from './components/buttons/pc-button/pc-button.component'
import { PCIconDropdownComponent } from './components/buttons/pc-icon-dropdown/pc-icon-dropdown.component'
import { CheckboxToggleComponent } from './components/checkbox-toggle/checkbox-toggle.component'
import { DocsComponent } from './components/docs/docs.component'
import { EmailChangeComponent } from './components/email-change/email-change.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { HighlightedTextComponent } from './components/highlighted-text/highlighted-text.component'
import { IamRegisterComponent } from './components/iam-register/iam-register.component'
import { IconComponent } from './components/icon/icon.component'
import { InvitationCallbackComponent } from './components/invitation-callback/invitation-callback.component'
import { KitchenSinkComponent } from './components/kitchen-sink/kitchen-sink.component'
import { PCFilterBarComponent } from './components/pc-filter-bar/pc-filter-bar.component'
import { PcInputCheckboxComponent } from './components/pc-input-checkbox/pc-input-checkbox.component'
import { PCInputChiplistComponent } from './components/pc-input-chiplist/pc-input-chiplist.component'
import { PCInputColourPickerComponent } from './components/pc-input-colour-picker/pc-input-colour-picker.component'
import { MondayDateAdapter, PCInputDateRangeComponent } from './components/pc-input-date-range/pc-input-date-range.component'
import { PcInputNumberComponent } from './components/pc-input-number/pc-input-number.component'
import { PCInputPasswordComponent } from './components/pc-input-password/pc-input-password.component'
import { PcInputSearchComponent } from './components/pc-input-search/pc-input-search.component'
import { PCInputTextareaComponent } from './components/pc-input-textarea/pc-input-textarea.component'
import { PCInputTextfieldComponent } from './components/pc-input-textfield/pc-input-textfield.component'
import { PcInputTimePickerComponent } from './components/pc-input-time-picker/pc-input-time-picker.component'
import { PCListItemComponent } from './components/pc-list-item/pc-list-item.component'
import { PCListSimpleItemComponent } from './components/pc-list-simple-item/pc-list-simple-item.component'
import { PCOverlayComponent } from './components/pc-overlay/pc-overlay.component'
import { PcScrollComponent } from './components/pc-scroll/pc-scroll.component'
import { PCSectionComponent } from './components/pc-section/pc-section.component'
import { PCStatusOverlayComponent } from './components/pc-status-overlay/pc-status-overlay.component'
import { PCTabBarComponent } from './components/pc-tab-bar/pc-tab-bar.component'
import { PCTableComponent } from './components/pc-table/pc-table.component'
import { PCTagComponent } from './components/pc-tag/pc-tag.component'
import { PCWarningBannerComponent } from './components/pc-warning-banner/pcwarning-banner.component'
import { ProficloudDropDownComponent } from './components/proficloud-drop-down/proficloud-drop-down.component'
import { ProficloudInformationBannerComponent } from './components/proficloud-information-banner/proficloud-information-banner.component'
import { ProficloudLogoComponent } from './components/proficloud-logo/proficloud-logo.component'
import { ProficloudMultiStatusOverlayComponent } from './components/proficloud-multi-status-overlay/proficloud-multi-status-overlay.component'
import { ProficloudNotificationBarComponent } from './components/proficloud-notification-bar/proficloud-notification-bar.component'
import { ProficloudSelectComponent } from './components/proficloud-select/proficloud-select.component'
import { ResetPasswordComponent } from './components/reset-password/reset-password.component'
import { TesterLoginComponent } from './components/tester-login/tester-login.component'
import { ThemedProficloudLogoComponent } from './components/themed-proficloud-logo/themed-proficloud-logo.component'
import { AutoFocusDirective } from './directives/auto-focus.directive'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { ContainerDirective } from './directives/container.directive'
import { CustomMatSpinnerDirective } from './directives/custom-mat-spinner.directive'
import { TemplateMarkerDirective } from './directives/template-marker.directive'
import { AbsoluteNumberPipe } from './pipes/absolute-number.pipe'
import { DateAgoPipe } from './pipes/date-ago.pipe'
import { DateFnsPipe } from './pipes/date-fns.pipe'
import { FilterPipe } from './pipes/filter.pipe'
import { FirmwareFilterPipe, FirmwareHistoryPipe } from './pipes/firmware-filter.pipe'
import { InputIsActivePipe } from './pipes/input-is-active.pipe'
import { MarkdownPipe } from './pipes/markdown.pipe'
import { MaskStringPipe } from './pipes/mask-string.pipe'
import { MaterialDateRangeDisplayPipe } from './pipes/material-date-range-display.pipe'
import { MiddleTruncatePipe } from './pipes/middle-truncate.pipe'
import { PcDatePipe } from './pipes/pc-date.pipe'
import { MapOverlayConfigPipe } from './pipes/pc-status-overlay.pipe'
import { PublicKeyvaluePipe } from './pipes/public-keyvalue.pipe'
import { SortByPipe } from './pipes/sort-by.pipe'
import { TranslIsocodePipe } from './pipes/transl-isocode.pipe'
import { TruncatePipe } from './pipes/truncate.pipe'

@NgModule({ declarations: [
        // pipes
        DateAgoPipe,
        TruncatePipe,
        PublicKeyvaluePipe,
        FirmwareFilterPipe,
        FirmwareHistoryPipe,
        FilterPipe,
        // directives
        ClickOutsideDirective,
        ContainerDirective,
        AutoFocusDirective,
        TemplateMarkerDirective,
        // components
        DeviceItemComponent,
        DeviceOnlineIconComponent,
        IconComponent,
        QuantitySelectComponent,
        PCOverlayComponent,
        ProficloudLogoComponent,
        ThemedProficloudLogoComponent,
        // integrate
        DocsComponent,
        AuthCallbackComponent,
        RegistrationCallbackComponent,
        TesterLoginComponent,
        MarkdownPipe,
        FilterPipe,
        ProficloudSelectComponent,
        HighlightedTextComponent,
        TranslIsocodePipe,
        CheckboxToggleComponent,
        IamRegisterComponent,
        InvitationCallbackComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        EmailChangeComponent,
        ProficloudNotificationBarComponent,
        ProficloudDropDownComponent,
        DateFnsPipe,
        SortByPipe,
        PCStatusOverlayComponent,
        ProficloudMultiStatusOverlayComponent,
        ProficloudInformationBannerComponent,
        PCFilterBarComponent,
        PCButtonComponent,
        PCIconDropdownComponent,
        PCListItemComponent,
        PCTagComponent,
        PCTabBarComponent,
        PCInputTextfieldComponent,
        PCTableComponent,
        PCListSimpleItemComponent,
        KitchenSinkComponent,
        PCSectionComponent,
        MapOverlayConfigPipe,
        PCInputTextareaComponent,
        PCInputChiplistComponent,
        PCInputColourPickerComponent,
        PCInputPasswordComponent,
        PcInputNumberComponent,
        MiddleTruncatePipe,
        PcInputSelectComponent,
        PCInputDateRangeComponent,
        PcInputCheckboxComponent,
        PcInputSearchComponent,
        PcScrollComponent,
        AbsoluteNumberPipe,
        MaterialDateRangeDisplayPipe,
        PcInputTimePickerComponent,
        PcButtonDropdownComponent,
        PCWarningBannerComponent,
        PcDatePipe,
        InputIsActivePipe,
        DeviceCssPrefixPipe,
        mapIconColorPipe,
        stateIconColorPipe,
        stateIconNamePipe,
        lastOnlineTextPipe,
        MaskStringPipe,
    ],
    exports: [
        // material
        MatChipsModule,
        // pipes
        DateAgoPipe,
        TruncatePipe,
        MiddleTruncatePipe,
        PublicKeyvaluePipe,
        FirmwareFilterPipe,
        FirmwareHistoryPipe,
        MarkdownPipe,
        FilterPipe,
        DateFnsPipe,
        // directives
        ClickOutsideDirective,
        ContainerDirective,
        AutoFocusDirective,
        TemplateMarkerDirective,
        // components
        IconComponent,
        QuantitySelectComponent,
        ProficloudNotificationBarComponent,
        PCOverlayComponent,
        ProficloudSelectComponent,
        DeviceItemComponent,
        DeviceOnlineIconComponent,
        CheckboxToggleComponent,
        ProficloudDropDownComponent,
        PCFilterBarComponent,
        PCStatusOverlayComponent,
        ProficloudMultiStatusOverlayComponent,
        // dev
        TranslIsocodePipe,
        // tooltip
        ProficloudLogoComponent,
        ThemedProficloudLogoComponent,
        SortByPipe,
        PCButtonComponent,
        PCListItemComponent,
        PCTagComponent,
        PCTabBarComponent,
        PCIconDropdownComponent,
        PCInputTextfieldComponent,
        PCInputTextareaComponent,
        PCInputChiplistComponent,
        PCTableComponent,
        PCListSimpleItemComponent,
        PCSectionComponent,
        PCInputColourPickerComponent,
        PCInputPasswordComponent,
        PcInputNumberComponent,
        PcInputSelectComponent,
        NgScrollbarModule,
        ProficloudInformationBannerComponent,
        HighlightedTextComponent,
        PCInputDateRangeComponent,
        PcInputCheckboxComponent,
        PcInputSearchComponent,
        PcButtonDropdownComponent,
        PcScrollComponent,
        AbsoluteNumberPipe,
        PCWarningBannerComponent,
        MaskStringPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatTooltip,
        AutosizeModule,
        TranslateModule.forChild({ extend: true }),
        NgScrollbarModule,
        DragDropModule,
        NgOptimizedImage,
        CustomMatSpinnerDirective,
        MatButton,
        MatIconButton], providers: [TruncatePipe, MiddleTruncatePipe, { provide: DateAdapter, useClass: MondayDateAdapter }, provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {}
