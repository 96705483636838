// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentInterface } from '@environments/environment.interface'

export const environment: EnvironmentInterface = {
  production: false,
  sentryEnv: 'DEVELOP',
  serviceBlacklist: [],
  version: 'dev',
  chinaMode: false,
  gateway: false,
  chargeRepay: false,
  name: 'dev',
  contactEmail: 'inbox@phoenixcontact-sb.io',
  copyright: '2024 © Proficloud. All rights reserved',
  dataPrivacyLink: 'https://proficloud.io/data-privacy',
  siteNoticeLink: 'https://proficloud.io/site-notice',
  softwareLicenceTerm: 'https://proficloud.io/software-license-terms-international',
  termsAndCondition: 'https://proficloud.io/info/terms-and-conditions',
  productDescription: 'https://proficloud.io/info/product-description',
  chargeRepayBaseURL: 'https://env.kaa.proficloud-development.io/emobility',
  chargeRepayInstallMgrURL: 'https://env.kaa.proficloud-development.io/charge-repay-installation-manager',
  googleMapsApiKey: 'AIzaSyDUSRENU8KgMEQ5pYN4Uv8xY371TBoIgBM',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
